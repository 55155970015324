import { defaultTheme, Provider, SSRProvider } from '@adobe/react-spectrum';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import Layout from '../components/layout';
import '../styles/globals.css';

export const jobTypes = [
  'Full-time',
  'Freelance / Contract',
  'Part-time',
  'Internship'
];

const App = ({ Component, pageProps }: AppProps) => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedJobTypes, setSelectedJobTypes] = useState(jobTypes);

  useEffect(() => {
    let storedIsDarkMode = localStorage.getItem('isDarkMode');

    if (storedIsDarkMode === null) {
      storedIsDarkMode = `${matchMedia('(prefers-color-scheme: dark)').matches}`;
    }

    localStorage.setItem('isDarkMode', storedIsDarkMode);

    document.documentElement.style.setProperty('color-scheme', storedIsDarkMode === 'true' ? 'dark' : 'light');

    setIsDarkMode(storedIsDarkMode === 'true');
  }, []);

  return (
    <>
      {process.env.NODE_ENV === 'production' && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-XV9S3QMXSL"
            strategy="afterInteractive"
          />
          <Script
            id="google-analytics"
            strategy="afterInteractive"
          >
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-XV9S3QMXSL');
            `}
          </Script>
        </>
      )}
      <SSRProvider>
        <Provider
          colorScheme={isDarkMode ? 'dark' : 'light'}
          locale="en-US"
          theme={defaultTheme}
        >
          <Layout
            isDarkMode={isDarkMode}
            setIsDarkMode={setIsDarkMode}
          >
            <Component
              {...pageProps}
              isDarkMode={isDarkMode}
              jobTypes={jobTypes}
              searchText={searchText}
              selectedJobTypes={selectedJobTypes}
              setSearchText={setSearchText}
              setSelectedJobTypes={setSelectedJobTypes}
            />
          </Layout>
        </Provider>
      </SSRProvider>
    </>
  );
};

export default App;
